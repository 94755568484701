import Home from '@/pages/publicPages/Home/Home';
import PageSEO from '@/shared/PageSEO/PageSEO';

export default function Index() {
  return (
    <>
      <PageSEO
        title="Rockalingua | Music based learning"
        description="Videos, songs, games,.. Hundreds of interactive ready to use activities. Everything you need for a successful school year!"
        includeSchemaMarkup={true}
      />
      <Home />
    </>
  );
}
